import './src/styles/global.css';
const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.async = true
    document.body.appendChild(script)
  }
  

  export const onClientEntry = () => {
    
    window.onload = () => {
        setTimeout(() => {
            addScript("https://fast.wistia.com/embed/medias/yrzo75bpk8.jsonp")
            addScript("https://fast.wistia.com/assets/external/E-v1.js")
        },10000)
            
        
    }
  }

